import React, { Component, Fragment } from 'react'
import {BrowserRouter,Routes,Route} from "react-router-dom";
import HomePage from '../pages/HomePage';
import UserLoginPage from '../pages/UserLoginPage';
import ContactPage from '../pages/ContactPage';
import PurchasePage from '../pages/PurchasePage';
import PrivacyPage from '../pages/PrivacyPage';
import ProductDetailPage from '../pages/ProductDetailPage';
import NotificationPage from '../pages/NotificationPage';
import FavouritePage from '../pages/FavouritePage';

export class AppRoute extends Component {
  render() {
    return (
      <Fragment>
            <BrowserRouter>                    
            <Routes>
                  <Route exact  path="/"  element={<HomePage />} />    
                  <Route exact  path="/login"  element={<UserLoginPage />} />  
                  <Route exact  path="/contact"  element={<ContactPage />} /> 
                  <Route exact  path="/purchase"  element={<PurchasePage />} />
                  <Route exact  path="/Privacy"  element={<PrivacyPage />} />   
                  <Route exact  path="/productdetails"  element={<ProductDetailPage />} /> 
                  <Route exact  path="/notification"  element={<NotificationPage />} />     

                  <Route exact  path="/favourite"  element={<FavouritePage />} />                
            </Routes>    
        </BrowserRouter>
      </Fragment>
    )
  }
}

export default AppRoute
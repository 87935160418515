import React, { Component, Fragment } from 'react'
import Slider from "react-slick";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Button from 'react-bootstrap/Button';
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
 import { faAngleRight } from '@fortawesome/free-solid-svg-icons'


class NewArrival extends Component {

  constructor(props){
      super(props);
      this.previous = this.previous.bind(this);
      this.next = this.next.bind(this);
  }
  next(){
    this.Slider.slickNext();
  }
  previous(){
    this.Slider.slickPrev();
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay:true,
      autoplayspeed:3000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      pauseOnHover: false,
      arrows:false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Fragment>
           <Container className='pt-3 pb-5 bg-body-tertiary'  fluid={true}>
              <div className="section-title text-center mb-55">
                <h2 className='text-center'>NEW ARRIVAL
                  <button className='btn btn-sm ml-2 site-btn' onClick={this.previous}  ><FontAwesomeIcon icon={faAngleLeft} /></button>
                  <button className='btn btn-sm ml-2 site-btn' onClick={this.next}  ><FontAwesomeIcon icon={faAngleRight} /></button>
                 
                </h2>
                <p>Some Of Our Exclusive Collection, You May Like</p>
              </div>
              <Row className='text-center'>
                  <Slider ref={c=>(this.Slider=c)} {...settings}>
                    <div>
                        <Card className='image-box card' >
                            <img className='center newarrival'   src="https://rukminim2.flixcart.com/image/612/612/xif0q/smartwatch/1/q/w/49-gladiator-max-android-ios-longwings-yes-original-imagurranzxvrrwg.jpeg?q=70"   alt="" />
                          <Card.Body>
                              <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                              <p className='product-price-on-card'>Price :₹59,999</p>
                          </Card.Body>
                        </Card>
                    </div>
                      <div>
                        <Card className='image-box card' >
                            <img className='center newarrival'  src="https://rukminim2.flixcart.com/image/612/612/xif0q/smartwatch/l/m/6/4-6-bxio2003-bxsm2003-bxsm2001-android-ios-beatxp-yes-original-imaguyfzcesjgfrr.jpeg?q=70" alt="" />
                          <Card.Body>
                              <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                              <p className='product-price-on-card'>Price :₹59,999</p>
                          </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className='image-box card' >
                            <img className='center newarrival'   src="https://rukminim2.flixcart.com/image/612/612/ksxjs7k0/smartwatch/o/q/t/android-sm-r890nzkainu-samsung-yes-original-imag6dtg5m6rztx6.jpeg?q=70" alt="" />
                          <Card.Body>
                              <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                              <p className='product-price-on-card'>Price :₹59,999</p>
                          </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className='image-box card' >
                            <img className='center newarrival' src="https://rukminim2.flixcart.com/image/612/612/xif0q/smartwatch/w/a/y/48-5-ultra-t-800-android-nova-yes-original-imagsarxqzzbzyag.jpeg?q=70" alt="" />
                          <Card.Body>
                              <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                              <p className='product-price-on-card'>Price :₹59,999</p>
                          </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className='image-box card' >
                            <img className='center newarrival' src="https://rukminim2.flixcart.com/image/612/612/xif0q/smartwatch/z/w/b/46-android-ios-fcsw02-e-french-connection-yes-original-imagr2bz4grgsky8.jpeg?q=70" alt="" />
                          <Card.Body>
                              <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                              <p className='product-price-on-card'>Price :₹59,999</p>
                          </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className='image-box card ' >
                            <img className='center newarrival' src="https://rukminim2.flixcart.com/image/612/612/xif0q/smartwatch/u/a/x/51-btalk-01-new-android-ios-ciberer-yes-original-imagtdpqgwzhqnty.jpeg?q=70" alt="" />
                          <Card.Body>
                              <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                              <p className='product-price-on-card'>Price :₹59,999</p>
                          </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className='image-box card ' >
                            <img className='center newarrival' src="https://rukminim2.flixcart.com/image/612/612/xif0q/smartwatch/a/h/u/1-22-android-s8-ultra-smartwatch-spadeaces-yes-original-imagzuc96djzjj9d.jpeg?q=70" alt="" />
                          <Card.Body>
                              <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                              <p className='product-price-on-card'>Price :₹59,999</p>
                          </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className='image-box card ' >
                            <img className='center newarrival' src="https://rukminim2.flixcart.com/image/612/612/xif0q/smartwatch/k/b/6/47-cosmos-android-ios-longwings-yes-original-imagwkhy7zzjn98q.jpeg?q=70" alt="" />
                          <Card.Body>
                              <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                              <p className='product-price-on-card'>Price :₹59,999</p>
                          </Card.Body>
                        </Card>
                    </div>  
                  </Slider>
              
              </Row>      

           </Container>
      </Fragment>
    )
  }
}

export default NewArrival
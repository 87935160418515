import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


class Collection extends Component {
  render() {
    return (
      <Fragment>
           <Container className='pt-3 pb-5 bg-body-tertiary'  fluid={true}>
         
              <div className="section-title text-center mb-55">
                <h2 className='text-center'>PRODUUCT COLLECTION</h2>
                <p>Some Of Our Exclusive Collection, You May Like</p>
              </div>                     
              <Row>
                  <Col className='p-3' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                    <Card className='image-box card w-100 text-center' >
                      <img className='center' src="https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/n/n/j/m-wx-d-pocket-102-white-woxen-original-imagqmsd799zvrpd.jpeg?q=70" alt="" />
                      <Card.Body>
                          <h3 className='product-name-on-card'>Men Regular Fit Solid Spread Collar Formal Shirt#JustHere</h3>
                          <p className='product-price-on-card'>Price :₹59,999</p>
                      </Card.Body>
                    </Card> 
                  </Col>
                  <Col className='p-3' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                    <Card className='image-box card w-100 text-center' >
                      <img className='center' src="https://rukminim2.flixcart.com/image/612/612/xif0q/shirt/b/d/f/3xl-13-lstr-wine-vtexx-original-imagnzbummhkgr7p.jpeg?q=70" alt="" />
                      <Card.Body>
                          <h3 className='product-name-on-card'>Men Regular Fit Solid Mandarin Collar Casual Shirt#JustHere</h3>
                          <p className='product-price-on-card'>Price :₹59,999</p>
                      </Card.Body>
                    </Card> 
                  </Col>
                  <Col className='p-3' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                    <Card className='image-box card w-100 text-center' >
                      <img className='center' src="https://rukminim2.flixcart.com/image/612/612/kv1a4cw0/shirt/h/l/b/s-hlsh012989-highlander-original-imag8yrztzqggjy8.jpeg?q=70" alt="" />
                      <Card.Body>
                          <h3 className='product-name-on-card'>Men Slim Fit Checkered Spread Collar Casual Shirt</h3>
                          <p className='product-price-on-card'>Price :₹59,999</p>
                      </Card.Body>
                    </Card> 
                  </Col>
                  <Col className='p-3' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                    <Card className='image-box card w-100 text-center' >
                      <img className='center' src="https://rukminim2.flixcart.com/image/612/612/xif0q/shirt/p/y/u/l-dlmshs23558-gn-dennis-lingo-original-imagsy5gutakyytp.jpeg?q=70" alt="" />
                      <Card.Body>
                          <h3 className='product-name-on-card'>Men Regular Fit Printed Spread Collar Casual Shirt</h3>
                          <p className='product-price-on-card'>Price :₹59,999</p>
                      </Card.Body>
                    </Card> 
                  </Col>
                  <Col className='p-3' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                    <Card className='image-box card w-100 text-center' >
                      <img className='center' src="https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/r/i/5/m-wx-d-pocket-102-maroon-woxen-original-imagr6ux2pjezquh.jpeg?q=70" alt="" />
                      <Card.Body>
                          <h3 className='product-name-on-card'>Men Regular Fit Printed Spread Collar Casual Shirt</h3>
                          <p className='product-price-on-card'>Price :₹59,999</p>
                      </Card.Body>
                    </Card> 
                  </Col>
                  <Col className='p-3' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                    <Card className='image-box card w-100 text-center' >
                      <img className='center' src="https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/q/l/z/l-wx-d-pocket-102-chiku-woxen-original-imagr6vaapdgjvqv.jpeg?q=70" alt="" />
                      <Card.Body>
                          <h3 className='product-name-on-card'>Men Regular Fit Printed Spread Collar Casual Shirt</h3>
                          <p className='product-price-on-card'>Price :₹59,999</p>
                      </Card.Body>
                    </Card> 
                  </Col>
                  <Col className='p-3' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                    <Card className='image-box card w-100 text-center' >
                      <img className='center' src="https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/7/x/s/m-wx-d-pocket-102-p-green-woxen-original-imagpggq8xubtwyc.jpeg?q=70" alt="" />
                      <Card.Body>
                          <h3 className='product-name-on-card'>Men Regular Fit Printed Spread Collar Casual Shirt</h3>
                          <p className='product-price-on-card'>Price :₹59,999</p>
                      </Card.Body>
                    </Card> 
                  </Col>
                  <Col className='p-3' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                    <Card className='image-box card w-100 text-center' >
                      <img className='center' src="https://rukminim2.flixcart.com/image/832/832/kwzap3k0/shirt/g/q/9/m-lstr-grey-p-v-creations-original-imag9jggsvfcgpp4.jpeg?q=70" alt="" />
                      <Card.Body>
                          <h3 className='product-name-on-card'>Men Regular Fit Printed Spread Collar Casual Shirt</h3>
                          <p className='product-price-on-card'>Price :₹59,999</p>
                      </Card.Body>
                    </Card> 
                  </Col>
                  
              </Row>
              
          </Container>     
      </Fragment>
    )
  }
}

export default Collection
import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

class Categories extends Component {
  render() {
    return (
      <Fragment>
        <Container className=' pt-3 pb-5' fluid={true}>
           <div className="section-title text-center mb-55">
              <h2 className='text-center'>Categories</h2>
              <p>Some Of Our Exclusive Collection, You May Like</p>
            </div>
            <Row>
              <Col key={1} xl={6} lg={6} md={2} sm={12} xs={12} >
                <Row>
                    <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/flap/64/64/image/29327f40e9c4d26b.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Grocery</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/flap/64/64/image/22fddf3c7da4c4f4.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Mobile</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim2.flixcart.com/fk-p-flap/64/64/image/0d75b34f7d8fbcb3.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Fashion</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim2.flixcart.com/flap/64/64/image/69c6589653afdb9a.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Electronics</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                    
                      
                  </Row>  
              </Col>
              {/* end Col */}
              <Col key={1} xl={6} lg={6} md={2} sm={12} xs={12} >
                <Row>
                    <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/flap/64/64/image/29327f40e9c4d26b.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Home & furniture</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/fk-p-flap/64/64/image/0139228b2f7eb413.jpg?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Appliances</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/flap/64/64/image/71050627a56b4693.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Travel</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim2.flixcart.com/flap/64/64/image/dff3f7adcf3a90c6.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Beauty Toys & More</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                    
                      
                  </Row>  
              </Col>
              {/* end Col */}
            </Row>
            <Row>
              <Col key={1} xl={6} lg={6} md={2} sm={12} xs={12} >
                <Row>
                    <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/flap/64/64/image/29327f40e9c4d26b.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Grocery</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/flap/64/64/image/22fddf3c7da4c4f4.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Mobile</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim2.flixcart.com/fk-p-flap/64/64/image/0d75b34f7d8fbcb3.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Fashion</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim2.flixcart.com/flap/64/64/image/69c6589653afdb9a.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Electronics</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                    
                      
                  </Row>  
              </Col>
              {/* end Col */}
              <Col key={1} xl={6} lg={6} md={2} sm={12} xs={12} >
                <Row>
                    <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/flap/64/64/image/29327f40e9c4d26b.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Home & furniture</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/fk-p-flap/64/64/image/0139228b2f7eb413.jpg?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Appliances</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim1.flixcart.com/flap/64/64/image/71050627a56b4693.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Travel</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                      <Col className='p-0' key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                          <Card className='h-100 w-100 text-center' >
                                
                              <Card.Body>
                                  <img className='center' src="https://rukminim2.flixcart.com/flap/64/64/image/dff3f7adcf3a90c6.png?q=100" alt="" />
                                  <h3 className='product-name-on-card'>Beauty Toys & More</h3>
                                  
                              </Card.Body>
                            </Card>
                      </Col>
                      {/* end Col */}
                    
                      
                  </Row>  
              </Col>
              {/* end Col */}
            </Row>
        </Container>
      </Fragment>
    )
  }
}

export default Categories
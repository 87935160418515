import React, { useState , Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faBars, faBell, faCartShopping, faHeart, faMobile,  faSearch } from '@fortawesome/free-solid-svg-icons'

const NotificationFun = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Fragment>
    <Container className="TopSection">
           <Row>
           <Col className=" p-1 " md={6} lg={6} sm={12} xs={12}>
               <Card onClick={handleShow} className="notification-card">
                   <Card.Body>
                       <h6> Lorem Ipsum is simply dummy text of the printing</h6>
                       <p className="py-1  px-0 text-primary m-0"><FontAwesomeIcon icon={faBell} />   Date: 22/12/2010 | Status: Unread</p>
                   </Card.Body>
               </Card>
           </Col>

           <Col className=" p-1 " md={6} lg={6} sm={12} xs={12}>
               <Card onClick={handleShow} className="notification-card">
                   <Card.Body>
                       <h6> Lorem Ipsum is simply dummy text of the printing</h6>
                       <p className="py-1   px-0 text-primary m-0"><FontAwesomeIcon icon={faBell} />   Date: 22/12/2010 | Status: Unread</p>
                   </Card.Body>
               </Card>
           </Col>

           <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
               <Card  className="notification-card">
                   <Card.Body>
                       <h6> Lorem Ipsum is simply dummy text of the printing</h6>
                       <p className="py-1  px-0 text-success m-0"><FontAwesomeIcon icon={faBell} />   Date: 22/12/2010 | Status: Read</p>
                   </Card.Body>
               </Card>

           </Col>

           <Col className="p-1" md={6} lg={6} sm={12} xs={12}>

               <Card className="notification-card">
                   <Card.Body>
                       <h5> Lorem Ipsum is simply dummy text of the printing</h5>
                       <p className="py-1  px-0 text-success m-0"><FontAwesomeIcon icon={faBell} />   Date: 22/12/2010 | Status: Read</p>
                   </Card.Body>
               </Card>

           </Col>

           <Col className="p-1" md={6} lg={6} sm={12} xs={12}>

               <Card className="notification-card">
                   <Card.Body>
                       <h6> Lorem Ipsum is simply dummy text of the printing</h6>
                       <p className="py-1  px-0 text-success m-0"><FontAwesomeIcon icon={faBell} />   Date: 22/12/2010 | Status: Read</p>
                   </Card.Body>
               </Card>

           </Col>

           <Col className="p-1" md={6} lg={6} sm={12} xs={12}>

               <Card className="notification-card">
                   <Card.Body>
                       <h6> Lorem Ipsum is simply dummy text of the printing</h6>
                       <p className="py-1 px-0 text-success m-0"><FontAwesomeIcon icon={faBell} />   Date: 22/12/2010 | Status: Read</p>
                   </Card.Body>
               </Card>

           </Col>

       </Row>

    </Container>
    <Modal show={show} onHide={handleClose}>
       <Modal.Header closeButton>
       <h6><FontAwesomeIcon icon={faBell} />Date:25/4/2024</h6>
       
       </Modal.Header>
       <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
       <Modal.Footer>
         <Button variant="secondary" onClick={handleClose}>
           Close
         </Button>
         
       </Modal.Footer>
     </Modal>
 </Fragment>
  )
}

export default NotificationFun
import React, { Component,Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';




class FeaturedProducts extends Component {
  render() {
    return (
      <Fragment>
        <Container  fluid={true}>
         
          <div className="section-title text-center mb-55">
            <h2 className='text-center'>FEATURED PRODUCT</h2>
            <p>Some Of Our Exclusive Collection, You May Like</p>
          </div>                     
          <Row>
              <Col className='pt-2 pb-5' key={1} xl={2} lg={2} md={2} sm={4} xs={6} >
                <Link to="/productdetails">
                <Card className='image-box card' >
                    <img className='center' src="https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/m/o/b/-original-imaghx9qkugtbfrn.jpeg?q=70" alt="" />
                  <Card.Body>
                      <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                      <p className='product-price-on-card'>Price :₹59,999</p>
                  </Card.Body>
                </Card> 
                </Link>
              </Col>
              <Col className='pt-2 pb-2' key={1} xl={2} lg={2} md={2} sm={4} xs={6} >
                <Card className='image-box card' >
                    <img className='center' src="https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/z/b/d/-original-imagpgx48f4szdvf.jpeg?q=70" alt="" />
                  <Card.Body>
                      <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                      <p className='product-price-on-card'>Price :₹59,999</p>
                  </Card.Body>
                </Card> 
              </Col>
              <Col className='pt-2 pb-2' key={1} xl={2} lg={2} md={2} sm={4} xs={6} >
                <Card className='image-box card' >
                    <img className='center' src="https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/h/a/k/-original-imagqx45wnzbh25s.jpeg?q=70" alt="" />
                  <Card.Body>
                      <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                      <p className='product-price-on-card'>Price :₹59,999</p>
                  </Card.Body>
                </Card> 
              </Col>
              <Col className='pt-2 pb-2' key={1} xl={2} lg={2} md={2} sm={4} xs={6} >
                <Card className='image-box card' >
                    <img className='center' src="https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/u/m/b/-original-imagrdefbw6bhbjr.jpeg?q=70" alt="" />
                  <Card.Body>
                      <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                      <p className='product-price-on-card'>Price :₹59,999</p>
                  </Card.Body>
                </Card> 
              </Col>
              <Col className='pt-2 pb-2' key={1} xl={2} lg={2} md={2} sm={4} xs={6} >
                <Card className='image-box card' >
                    <img className='center' src="https://rukminim2.flixcart.com/image/416/416/xif0q/mobile/b/h/c/-original-imagth5xwrg4gfyp.jpeg?q=70" alt="" />
                  <Card.Body>
                      <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                      <p className='product-price-on-card'>Price :₹59,999</p>
                  </Card.Body>
                </Card> 
              </Col>
              <Col className='pt-2 pb-2' key={1} xl={2} lg={2} md={2} sm={4} xs={6} >
                <Card className='image-box card' >
                    <img className='center' src="https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/9/e/4/-original-imagtcrumzbqj4xd.jpeg?q=70" alt="" />
                  <Card.Body>
                      <h3 className='product-name-on-card'>APPLE iPhone 14 (Starlight, 128 GB)</h3>
                      <p className='product-price-on-card'>Price :₹59,999</p>
                  </Card.Body>
                </Card> 
              </Col>
              
              
              
          </Row>
      </Container>
      </Fragment>
    )
  }
}

export default FeaturedProducts
import React, { Component,Fragment } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';


class MegaMenuAll extends Component {
  render() {
    return (
      <Fragment>
            <div className='Accordion'>
        <Accordion defaultActiveKey="none">
      <Accordion.Item eventKey="0">
        <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Men's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
           
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
      <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Women's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
      <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Women's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
      <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Women's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
      <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Women's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
      <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Women's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
      <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Women's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
      <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Women's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
      <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Women's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><a href='#'   >Mans Tshirt</a></li>
            <li><a href='#'  >Mans Jeans</a></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
      <Accordion.Header> 
             <img className='accordionMenuIcon' src='https://cdn-icons-png.flaticon.com/128/739/739249.png' alt=''  />
             <span className='m-2'>Women's Clothing</span>
        </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li><Link>Mans Tshirt</Link></li>
            <li><a href='#'  >Mans Jeans</a></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
      </Fragment>
    )
  }
}

export default MegaMenuAll
import React, { Component, Fragment } from 'react'
// import Button from 'react-bootstrap/Button';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAddressBook, faCoffee, faHome } from '@fortawesome/free-solid-svg-icons'

import AppRoute from './route/AppRoute';

class App extends Component {
  render() {
    return (
      <Fragment>
          <AppRoute />
      </Fragment>
      
    )
  }
}

export default App 
import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Logo from "../../assets/images/easyshop.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faBars, faBell, faCartShopping, faHeart, faMobile,  faSearch } from '@fortawesome/free-solid-svg-icons'
import {BrowserRouter,Routes,Route,NavLink,Link} from "react-router-dom";
import MegaMenuAll from '../home/MegaMenuAll';




 class NavMenuDesktop extends Component {
  constructor(props){
    super(props);
    this.state={
      SideNavState : 'sideNavClose',
      ContentOverlayState: 'ContentOverlayClose'
    }
  }

  MenuBarClickHandler = () => {
    this.SideNavOpenClose();
  }

  ContentOverlayClickHandler = () => {
    this.SideNavOpenClose();
  }

  SideNavOpenClose = () => {
      let  SideNavState = this.state.SideNavState;
      let  ContentOverlayState = this.state.ContentOverlayState;

      if(SideNavState==="sideNavOpen"){
        this.setState({
          SideNavState : 'sideNavClose',
          ContentOverlayState: 'ContentOverlayClose'
        })
      } else {

        this.setState({
          SideNavState : 'sideNavOpen',
          ContentOverlayState: 'ContentOverlayOpen'
        })

      }
  }
  render() {
    return (
      <Fragment>
        <div className='marginbottom'>  
          <Navbar  fixed={'top'} bg="light">
            <Container fluid className='bg-white fixed-top shadow-sm p-2 mb-0'>
              <Row  className='w-100'>
                <Col className='p-1 mt-1' lg={4} md={4} sm={12} xs={12}>
                  <Button variant="info"  onClick={this.MenuBarClickHandler}><FontAwesomeIcon icon={faBars} /></Button>
                  <Link to="/"><img src={Logo} className="d-inline-block align-left" alt="logo" /></Link></Col>
                <Col className='p-1 mt-2'  lg={4} md={4} sm={12} xs={12}>
                  <InputGroup className='w-100'>
                      
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className=" mr-sm-2"
                      />
                      <Button variant="info" ><FontAwesomeIcon icon={faSearch} /></Button>
                    
                  </InputGroup> 
                </Col>
                <Col className='p-1 mt-2' lg={4} md={4} sm={12} xs={12}> 
                 <Link className='fs-4 p-lg-3 '  style={{color: "black"}}  to="/favourite">
                      <FontAwesomeIcon  icon={faHeart} />
                      <sup  style={{color: "red"}} ><span className='badge text-white bg-danger'>5</span></sup>
                  </Link>
                  <Link className='fs-4 p-lg-3'  style={{color: "black"}}  to="/notification">
                      <FontAwesomeIcon icon={faBell} />
                      <sup  style={{color: "red"}} ><span className='badge text-white bg-danger'>5</span></sup>
                  </Link>
                  <Link className='fs-4 ml-3 text-bg-dark'   to="/" ><FontAwesomeIcon    icon={faMobile} /></Link> 
                  <Link className='h4 btn' to="/login">LOGIN</Link>
                  <Button    variant="info"><FontAwesomeIcon icon={faCartShopping} ></FontAwesomeIcon> 5 item</Button>
                </Col>
                
              </Row>

             </Container>
           </Navbar>
        </div>
        <div className={this.state.SideNavState}>
               <MegaMenuAll />
          </div>
           <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverlayState}></div>
      </Fragment>
    )
  }
}

export default NavMenuDesktop
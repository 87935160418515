import React, { Component, Fragment } from 'react';

import HomeTop from '../components/home/HomeTop';
import HomeTopMobile from '../components/home/HomeTopMobile';
import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';
import { useMediaQuery } from 'react-responsive'
import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import UserLogin from '../components/common/UserLogin';



class UserLoginPage extends Component {
  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    const Desktop = ({ children }) => {
      const isDesktop = useMediaQuery({ minWidth: 992 })
      return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
      const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
      return isTablet ? children : null
    }
    const Mobile = ({ children }) => {
      const isMobile = useMediaQuery({ maxWidth: 767 })
      return isMobile ? children : null
    }
    const Default = ({ children }) => {
      const isNotMobile = useMediaQuery({ minWidth: 768 })
      return isNotMobile ? children : null
    }
    return (
      <Fragment > 
        
          <Desktop>
            <NavMenuDesktop />       
          </Desktop>
          <Tablet>
            <NavMenuMobile />
          </Tablet>
          <Mobile>
            <NavMenuMobile />       
          </Mobile>

          <UserLogin />

          <Desktop><FooterDesktop /></Desktop>
          <Tablet><FooterMobile /></Tablet>
          <Mobile><FooterMobile /></Mobile>
          
      </Fragment>
    )
  }
}

export default UserLoginPage
import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

 class Purchase extends Component {
  render() {
    return (
      <Fragment>
      <Container>
      <Row className="p-2">
<Col className="shadow-sm bg-white mt-2" md={12} lg={12} sm={12} xs={12}>
<h4 className="section-title-login">Privacy Page </h4>
<p className="section-title-contact">
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
 ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
 laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
  voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
   cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
</p>


           </Col>
      </Row>
 </Container>
 </Fragment>
    )
  }
}

export default Purchase
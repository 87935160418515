import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Banner from "../../assets/images/beat-the-price-try-us-before-you-go-somewhere-us-1.webp"
import Slider2 from "../../assets/images/slider2.png"
import Slider3 from "../../assets/images/slider3.png"
//import Logo from "../../assets/images/easyshop.png"


class HomeSlider extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay:true,
      autoplayspeed:3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      pauseOnHover: false,
      arrows:false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
        <div>
          
          <Slider {...settings}>
          <div>
          <img className="slider-img" src={Banner} alt='Banner' />
          </div>
          <div>
          <img className="slider-img" src={Slider2} alt='slider2' />
          </div>
          <div>
          <img className="slider-img" src={Slider3} alt='slider3' />
          </div>
         
        </Slider>
        </div>
    )
  }
}

export default HomeSlider
import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';


import { faFaceSmileBeam  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { FaFacebook,FaTwitter,FaInstagram } from "react-icons/fa";
import Google from '../../assets/images/google.png'
import Apple from '../../assets/images/apple.png'

export class FooterDesktop extends Component {
  render() {
    return (
      <Fragment>
          <Container fluid className='footerback shadow-sm pt-3 mt-5 pb-3'>
            <Container>
              <Row className='px-0 my-5'>
                  <Col className='p-3' lg="3" md="3" sm="6" xs="12">
                    <h5 className='footer-menu-title'>OFFICE ADDRESS</h5>
                    <p>Blacksburg, VA 24061, United States</p>
                    <span>Email:pinjubp@gmail.com</span>
                    <h5 className='footer--menu--title'>SOCIAL LINK</h5>
                    <Link to='/'><FaFacebook className='icon-style'  /></Link>
                    <Link to='/'><FaTwitter className='icon-style'  /></Link>
                    <Link to='/'><FaInstagram className='icon-style'  /></Link>
                  </Col>
                  <Col className='p-3' lg="3" md="3" sm="6" xs="12">
                  <h5 className='footer-menu-title'>THE COMPANY</h5>
                    <Link to='/' className='footer-link d-block mt-1 mb-1'>About Us</Link>
                    <Link to='/' className='footer-link d-block mt-1 mb-1'>Company Profile</Link>
                    <Link to='/contact' className='footer-link d-block mt-1 mb-1'>Contact Us</Link>
                  </Col>
                  <Col className='p-3' lg="3" md="3" sm="6" xs="12">
                    <h5 className='footer-menu-title'>MORE INFO</h5>
                    <Link to='/purchase' className='footer-link d-block mt-1 mb-1'>How To Purchase</Link>
                    <Link to='/Privacy' className='footer-link d-block mt-1 mb-1'>Privacy Policy</Link>
                    <Link to='/' className='footer-link d-block mt-1 mb-1'>Refund</Link>
                  </Col>
                  <Col className='p-3' lg="3" md="3" sm="6" xs="12">
                    <h5 className='footer-menu-title'>DOWNLOAD APP</h5>
                    <Link to='/' className='footer-link d-block mt-2 mb-2'><img src={Google} alt='Google' /></Link>
                    <Link to='/' className='footer-link d-block mt-2 mb-2'><img src={Apple} alt='Apple' /></Link>
                  </Col>
              </Row>
              
            </Container>
          </Container>
          <Container fluid className='bg-dark'>
              <Container>
                <Row className='text-center p-3 text-light'>
                    <p>&copy; Copyright 2024 Nava Shhop , All rights Reserved</p>
                </Row>
              </Container>
          </Container>
      </Fragment>
    )
  }
}

export default FooterDesktop
import React, { Component ,Fragment} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Login from '../../assets/images/login.png'

 class Contact extends Component {
  render() {
    return (
      <Fragment>
          <Container>
              <Row className='p-2'>
                  <Col className='shadow-sm bg-white mt-2' lg={12} md={12} sm={12} xs={12} > 
                  
                    <Row className='text-center'>
                        <Col className='d-flex justify-content-center' lg={6} md={6} sm={12} xs={12}>
                          <Form className="onboardForm">
                              <h4 className="section-title-login"> CONTACT WITH US </h4>
                              <h6 className="section-sub-title">Please Contact With Us</h6>
                              <input className="form-control m-2" type="text" placeholder="Enter Mobile Number" />
                              <input className="form-control m-2" type="email" placeholder="Enter Your Email" />
                              <textarea className="form-control m-2" type="text" placeholder="Enter your Message" ></textarea>
                              <Button className="btn btn-block m-2 site-btn-login"> Send </Button>

                          </Form>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                        <p className="section-title-contact">
                        1635 Franklin Street Montgomery, Near Sherwood Mall. AL 36104
                        Email: Support@navawebstudio.com
                        </p>
                        <iframe title='Google map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d162771.1102477064!2d-74.10054944459704!3d40.70681480276415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1627241390779!5m2!1sen!2sbd"
                         width="600" height="450" styles="border:0;" allowfullscreen="" loading="lazy"></iframe>
                        </Col>
                    </Row>
                  </Col>
              </Row>

          </Container>
      </Fragment>
    )
  }
}

export default Contact
import React, { Component,Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faCartShopping, faH, faHome, faMobile, faSearch } from '@fortawesome/free-solid-svg-icons';
import {BrowserRouter,Routes,Route,NavLink,Link} from "react-router-dom";
import Logo from "../../assets/images/easyshop.png";
import ListGroup from 'react-bootstrap/ListGroup';
import MegaMenuMobile from '../home/MegaMenuMobile';

class NavMenuMobile extends Component {
  constructor(props){
    super(props);
    this.state={
      SideNavState : 'sideNavClose',
      ContentOverlayState: 'ContentOverlayClose'
    }
  }

  MenuBarClickHandler = () => {
    this.SideNavOpenClose();
  }

  ContentOverlayClickHandler = () => {
    this.SideNavOpenClose();
  }

  SideNavOpenClose = () => {
      let  SideNavState = this.state.SideNavState;
      let  ContentOverlayState = this.state.ContentOverlayState;

      if(SideNavState==="sideNavOpen"){
        this.setState({
          SideNavState : 'sideNavClose',
          ContentOverlayState: 'ContentOverlayClose'
        })
      } else {

        this.setState({
          SideNavState : 'sideNavOpen',
          ContentOverlayState: 'ContentOverlayOpen'
        })

      }
  }
  render() {
    return (
      <Fragment>
          <Container fluid className='bg-white fixed-top shadow-sm p-2 mb-0'>
              <Row  className='w-100'>
                <Col className='p-1 mt-1' lg={4} md={4} sm={12} xs={12}>
                  <Button onClick={this.MenuBarClickHandler}><FontAwesomeIcon icon={faBars} /></Button>
                  <Link to="/"><img src={Logo} className="d-inline-block align-left" alt="logo" /></Link>
                  <Button variant="primary"><FontAwesomeIcon icon={faCartShopping} /> 5 item</Button>
                </Col>
                
                
              </Row>

          </Container>
          
          <div className={this.state.SideNavState}>
               <MegaMenuMobile />
          </div>
           <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverlayState}></div>
      </Fragment>
    )
  }
}

export default NavMenuMobile
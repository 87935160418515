import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Login from '../../assets/images/login.png'


 class UserLogin extends Component {
  render() {
    const Desktop = ({ children }) => {
      const isDesktop = useMediaQuery({ minWidth: 992 })
      return isDesktop ? children : null
    }
    const Tablet = ({ children }) => {
      const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
      return isTablet ? children : null
    }
   
    return (
      <Fragment>
          <Container>
              <Row className='p-2'>
                  <Col className='shadow-sm bg-white mt-2' lg={12} md={12} sm={12} xs={12} > 
                  
                    <Row className='text-center'>
                        <Col className='d-flex justify-content-center' lg={6} md={6} sm={12} xs={12}>
                          <Form className="onboardForm">
                              <h4 className="section-title-login"> USER SING IN </h4>
                              <h6 className="section-sub-title">Please Enter Your Mobile Number</h6>
                              <input className="form-control m-2" type="text" placeholder="Enter Mobile Number" />
                              <Button className="btn btn-block m-2 site-btn-login"> Next </Button>

                          </Form>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                        <Desktop>
                          <img className="onboardBanner" src={Login}  alt='' />
                        </Desktop> 
                        <Tablet>
                          <img className="onboardBanner" src={Login}  alt='' />
                        </Tablet>
                        </Col>
                    </Row>
                  </Col>
              </Row>

          </Container>
      </Fragment>
    )
  }
}

export default UserLogin